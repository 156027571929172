import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'
import { useLoggedIn } from '@netpurpose/core'
import { Spinner } from '@netpurpose/np-ui'
import { ShowIfAuthorised } from '#components/ShowIfAuthorised'
import { routes } from '#config'

const ProtectedIndex: FC = () => {
  const { user } = useLoggedIn()
  const router = useRouter()

  useEffect(() => {
    if (user?.canAccessFacts) {
      router.push(routes.facts)
      // NOTE: Don't redirect until it is specifically false, as initially will
      // be undefined.
    } else if (user?.canExtract) {
      // NOTE: Assume user is a data extractor if they can't access facts but can
      // extract and so would most likely need redirecting to the collection page.
      router.push(routes.collectionStart)
    } else if (user?.canAccessUsers) {
      // NOTE: this means that they're likely sales and need access to manage users and groups
      router.push(routes.users)
    }
  }, [user, router])

  return (
    <ShowIfAuthorised isAuthorised={user && true} title="">
      <Spinner />
    </ShowIfAuthorised>
  )
}

export default ProtectedIndex
